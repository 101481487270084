import {
  Badge,
  Button,
  Heading,
  HStack,
  List,
  Icon,
  ListItem,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../Layout/PageLayout";
import { events } from "./data";
import {
  GiSoccerBall,
  GiTennisBall,
  GiRunningShoe,
  GiHorseHead,
  GiBasketballBall,
  GiCricketBat,
} from "react-icons/gi";
import {
  FaBaseball,
  FaCar,
  FaGolfBallTee,
  FaMedal,
  FaMotorcycle,
} from "react-icons/fa6";
import { MdOutlineSports, MdSportsRugby } from "react-icons/md";

const resolveEventCategoryIcon = (eventCategory: string) => {
  switch (eventCategory) {
    case "football":
      return {
        icon: GiSoccerBall,
        color: "blue",
      };
    case "tennis":
      return {
        icon: GiTennisBall,
        color: "green",
      };
    case "athletics":
      return {
        icon: GiRunningShoe,
        color: "red",
      };
    case "Formula 1":
      return {
        icon: FaCar,
        color: "black",
      };
    case "motoGP":
      return {
        icon: FaMotorcycle,
        color: "grey",
      };
    case "rugby":
      return {
        icon: MdSportsRugby,
        color: "#414141",
      };
    case "rhythmic gymnastics":
      return {
        icon: MdOutlineSports,
        color: "pink",
      };
    case "horse racing":
      return {
        icon: GiHorseHead,
        color: "brown",
      };
    case "golf":
      return {
        icon: FaGolfBallTee,
        color: "white",
      };
    case "cricket":
      return {
        icon: GiCricketBat,
        color: "red",
      };
    case "baseball":
      return {
        icon: FaBaseball,
        color: "white",
      };
    case "basketball":
      return {
        icon: GiBasketballBall,
        color: "orange",
      };
    case "multiple sports":
      return {
        icon: FaMedal,
        color: "gold",
      };
    default:
      return {
        icon: null,
        color: "grey",
      };
  }
};

export default function EventsIndex() {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <VStack width="full" alignItems="center">
        <Heading size="xs" textAlign="center" mt={20}>
          Upcoming International Country Events
        </Heading>
        <Button
          variant="countryGradient"
          onClick={() => {
            navigate("/");
          }}
        >
          Back To List
        </Button>
      </VStack>
      <Stack spacing="5" flex="1" mt={20} mb={4}>
        <List listStyleType="none">
          <Stack spacing="3" width="full">
            {events.map((event) => (
              <ListItem
                key={event.id}
                value={event.id}
                bg="bg.surface"
                p="4"
                boxShadow="sm"
                position="relative"
                borderRadius="lg"
                cursor="grab"
              >
                <Stack shouldWrapChildren spacing="4">
                  <Text textStyle="lg" fontWeight="bold" color="fg.emphasized">
                    {event.title}
                  </Text>
                  <HStack justify="space-between">
                    <HStack spacing={4}>
                      <Badge colorScheme="green" size="sm">
                        {event.type}
                      </Badge>
                      <Icon
                        as={
                          resolveEventCategoryIcon(event.type).icon ?? FaMedal
                        }
                      />
                    </HStack>
                    <VStack spacing="3" alignItems="end">
                      <Text textStyle="md" fontWeight="medium">
                        {event.location}
                      </Text>
                      <Text
                        textStyle="md"
                        color="fg.subtle"
                        fontWeight="medium"
                      >
                        {event.date}
                      </Text>
                    </VStack>
                  </HStack>
                </Stack>
              </ListItem>
            ))}
          </Stack>
        </List>
      </Stack>
    </PageLayout>
  );
}
