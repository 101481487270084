import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
} from "@chakra-ui/react";

export type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  header?: React.ReactNode;
  showCloseButton?: boolean;
  placement?: "left" | "right" | "top" | "bottom";
  size?: string;
};

export const BaseDrawer = ({
  isOpen,
  onClose,
  children,
  header = <></>,
  showCloseButton = true,
  placement = "left",
  size = "xs",
}: DrawerProps) => {
  return (
    <Drawer placement={placement} isOpen={isOpen} onClose={onClose} size={size}>
      <DrawerOverlay />
      <DrawerContent>
        {showCloseButton && <DrawerCloseButton />}
        {header}
        <DrawerBody mt={8}>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
