import axios from "axios";

export type TokenType = {
  address: string;
  name: string;
  symbol: string;
};

export type Pair = {
  chainId: string;
  dexId: string;
  url: string;
  pairAddress: string;
  baseToken: TokenType;
  quoteToken: TokenType;
  priceNative: string;
  priceUsd: string;
  txns: {
    m5: {
      buys: number;
      sells: number;
    };
    h1: {
      buys: number;
    };
    h6: {
      buys: number;
      sells: number;
    };
    h24: {
      buys: number;
      sells: number;
    };
  };
  volume: {
    h24: number;
    h6: number;
    h1: number;
    m5: number;
  };
  priceChange: {
    m5: number;
    h1: number;
    h6: number;
    h24: number;
  };
  liquidity: {
    usd: number;
    base: number;
    quote: number;
  };

  fdv: number;
  pairCreatedAt: number;
  info: {
    imageUrl: string;
    websites: {
      label: string;
      url: string;
    }[];
    socials: {
      type: string;
      url: string;
    }[];
  };
};

// All we need for now
export type PairDetails = {
  cg: string;
  gp: string;
  ts: string;
  cmc: string;
  holders: {
    count: number;
  };
};

export const dexscreener = () => {
  const DEX_SCREENER_TOKEN_BASE_URL =
    "https://api.dexscreener.com/latest/dex/tokens";

  const getTokenInfo = async (
    contractAddress: string,
  ): Promise<Pair | undefined> => {
    try {
      type PairResponse = {
        pairs: Pair[];
      };
      const r = await axios.get<PairResponse>(
        `${DEX_SCREENER_TOKEN_BASE_URL}/${contractAddress}`,
      );

      if (!r.data || !r.data.pairs || r.data.pairs.length <= 0) {
        return undefined;
      }

      const pairData = r.data.pairs[0];
      if (
        pairData.baseToken.address ===
        "Ehk7QXFVqHkyqSpcfmUTCm5mJ9wE5b2axkC6Emcv7Qh1"
      ) {
        pairData.info.socials = [
          { url: "https://twitter.com/MEXICO_COIN", type: "twitter" },
          { url: "t.me/mexicosol", type: "telegram" },
        ];
      }

      if (
        pairData.baseToken.address.toLowerCase() ===
        "2GEwopJhqVu7ZpzpjSQa2E5pSDyGhJADGRcnzWaaD9EG".toLowerCase()
      ) {
        pairData.info.socials = [
          { url: "https://twitter.com/yensolana", type: "twitter" },
          { url: "https://t.me/JapaneseCoin", type: "telegram" },
        ];
      }

      return pairData;
    } catch (e) {}

    return undefined;
  };

  return {
    getTokenInfo,
  };
};
