import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ReactGa from "react-ga";

ReactGa.initialize("G-NH7BBNVMF8");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<App />);
