export const events = [
  {
    id: "1",
    type: "football",
    title: "UEFA Women’s Under-17 Championship",
    date: "5 - 18 May 2024",
    location: "Sweden",
  },
  {
    id: "2",
    type: "tennis",
    title: "Internazionali BNL d’Italia",
    date: "6 – 19 May 2024",
    location: "Rome, Italy",
  },
  {
    id: "3",
    type: "football",
    title: "UEFA Champions League Semifinals Leg 2",
    date: "7 – 8 May 2024",
    location: "Europe",
  },
  {
    id: "4",
    type: "athletics",
    title: "Doha Diamond League",
    date: "10 May 2024",
    location: "Doha, Qatar",
  },
  {
    id: "5",
    type: "Formula E",
    title: "Berlin ePrix Round 9 & 10",
    date: "11 – 12 May 2024",
    location: "Berlin, Germany",
  },
  {
    id: "6",
    type: "motoGP",
    title: "Grand Prix de France",
    date: "12 May 2024",
    location: "Le Mans, France",
  },
  {
    id: "7",
    type: "golf",
    title: "PGA Championship",
    date: "13 – 19 May 2024",
    location: "Valhalla Golf Club, Louisville, KY, USA",
  },
  {
    id: "8",
    type: "Formula 1",
    title: "Formula 1 Imola Grand Prix",
    date: "17 – 19 May 2024",
    location: "Imola, Italy",
  },
  {
    id: "9",
    type: "horse racing",
    title: "Preakness Stakes",
    date: "18 May 2024",
    location: "Baltimore, USA",
  },
  {
    id: "10",
    type: "athletics",
    title: "Rabat Diamond League",
    date: "19 May 2024",
    location: "Rabat, Morocco",
  },
  {
    id: "11",
    type: "football",
    title: "UEFA European Under-17 Championship",
    date: "20 May – 5 June 2024",
    location: "Cyprus",
  },
  {
    id: "12",
    type: "tennis",
    title: "Roland Garros",
    date: "20 May – 9 June 2024",
    location: "Paris, France",
  },
  {
    id: "13",
    type: "football",
    title: "2024 UEFA Europa League Final",
    date: "22 May 2024",
    location: "Dublin, Ireland",
  },
  {
    id: "14",
    type: "rhythmic gymnastics",
    title: "2024 Rhythmic Europeans",
    date: "22 – 26 May 2024",
    location: "Budapest, Hungary",
  },
  {
    id: "15",
    type: "rugby",
    title: "Challenge Cup Final",
    date: "24 May 2024",
    location: "London, UK",
  },
  {
    id: "16",
    type: "Formula 1",
    title: "Formula 1 Grand Prix de Monaco",
    date: "24 – 26 May 2024",
    location: "Monaco",
  },
  {
    id: "17",
    type: "rugby",
    title: "Investec Champions Cup Final",
    date: "25 May 2024",
    location: "London, England",
  },
  {
    id: "18",
    type: "Formula E",
    title: "Shanghai ePrix Round 11 & 12",
    date: "25 – 26 May 2024",
    location: "Shanghai, China",
  },
  {
    id: "19",
    type: "football",
    title: "FA Cup Final",
    date: "25 May 2024",
    location: "London, UK",
  },
  {
    id: "20",
    type: "football",
    title: "2024 UEFA Women’s Champions League Final",
    date: "25 May 2024",
    location: "Bilbao, Spain",
  },
  {
    id: "21",
    type: "athletics",
    title: "Eugene Diamond League",
    date: "25 May 2024",
    location: "Eugene, USA",
  },
  {
    id: "22",
    type: "motoGP",
    title: "Gran Premi de Catalunya",
    date: "26 May 2024",
    location: "Barcelona, Spain",
  },
  {
    id: "23",
    type: "football",
    title: "2024 UEFA Europa Conference League Final",
    date: "29 May 2024",
    location: "Athens, Greece",
  },
  {
    id: "24",
    type: "athletics",
    title: "Oslo Diamond League",
    date: "30 May 2024",
    location: "Oslo, Norway",
  },
  {
    id: "25",
    type: "football",
    title: "2024 UEFA Champions League Final",
    date: "1 June 2024",
    location: "London, UK",
  },
  {
    id: "26",
    type: "cricket",
    title: "ICC Men’s T20 World Cup",
    date: "1 – 29 June 2024",
    location: "West Indies & USA",
  },
  {
    id: "27",
    type: "athletics",
    title: "Stockholm Diamond League",
    date: "2 June 2024",
    location: "Stockholm, Sweden",
  },
  {
    id: "28",
    type: "motoGP",
    title: "Gran Premio D’Italia",
    date: "2 June 2024",
    location: "Mugello, Italy",
  },
  {
    id: "29",
    type: "football",
    title: "Official or friendly matches",
    date: "3 – 11 June 2024",
    location: "worldwide",
  },
  {
    id: "30",
    type: "basketball",
    title: "Start NBA Finals 2024",
    date: "6 – 23 June 2024",
    location: "USA",
  },
  {
    id: "31",
    type: "Formula 1",
    title: "Formula 1 Grand Prix du Canada",
    date: "7 – 9 June 2024",
    location: "Montréal, Canada",
  },
  {
    id: "32",
    type: "athletics",
    title: "European Athletics Championships",
    date: "7 – 12 June 2024",
    location: "Rome, Italy",
  },
  {
    id: "33",
    type: "football",
    title: "UEFA EURO 2024",
    date: "14 June – 14 July 2024",
    location: "Germany",
  },
  {
    id: "34",
    type: "tennis",
    title: "Cinch Championships",
    date: "15 – 23 June 2024",
    location: "London, Great Britain",
  },
  {
    id: "35",
    type: "tennis",
    title: "Terra Wortmann Open",
    date: "15 – 23 June 2024",
    location: "Halle, Germany",
  },
  {
    id: "36",
    type: "motoGP",
    title: "Grand Prix of Kazakhstan",
    date: "16 June 2024",
    location: "Sokol Circuit, Kazakhstan",
  },
  {
    id: "37",
    type: "football",
    title: "CONMEBOL Copa America 2024",
    date: "20 June – 14 July 2024",
    location: "USA",
  },
  {
    id: "38",
    type: "Formula 1",
    title: "Formula 1 Aramco Gran Premio de España",
    date: "21 – 23 June 2024",
    location: "Barcelona, Spain",
  },
  {
    id: "39",
    type: "basketball",
    title: "NBA Draft 2024",
    date: "26 – 27 June 2024",
    location: "New York, NY, USA",
  },
  {
    id: "40",
    type: "Formula 1",
    title: "Formula 1 Rolex Grosser Preis von Osterreich",
    date: "28 – 30 June 2024",
    location: "Spielberg, Austria",
  },
  {
    id: "41",
    type: "Formula E",
    title: "Portland ePrix Round 13 & 14",
    date: "29 – 30 June 2024",
    location: "Portland, USA",
  },
  {
    id: "42",
    type: "motoGP",
    title: "TT Assen",
    date: "30 June 2024",
    location: "Assen, Netherlands",
  },
  {
    id: "43",
    type: "tennis",
    title: "Wimbledon",
    date: "1 – 14 July 2024",
    location: "London, Great Britain",
  },
  {
    id: "44",
    type: "Formula 1",
    title: "Formula 1 Pirelli British Grand Prix",
    date: "5 – 7 July 2024",
    location: "Silverstone, UK",
  },
  {
    id: "45",
    type: "motoGP",
    title: "Motorrad Grand Prix Deutschland",
    date: "7 July 2024",
    location: "Sachsenring, Germany",
  },
  {
    id: "46",
    type: "athletics",
    title: "Paris Diamond League",
    date: "7 July 2024",
    location: "Paris, France",
  },
  {
    id: "47",
    type: "athletics",
    title: "Monaco Diamond League",
    date: "12 July 2024",
    location: "Monaco",
  },
  {
    id: "48",
    type: "baseball",
    title: "MLB Draft 2024",
    date: "14 – 16 July 2024",
    location: "Fort Worth, TX, USA",
  },
  {
    id: "49",
    type: "football",
    title: "UEFA Women’s Under-19 Championship",
    date: "14 – 27 July 2024",
    location: "Lithuania",
  },
  {
    id: "50",
    type: "football",
    title: "UEFA European Under-19 Championship",
    date: "15 – 28 July 2024",
    location: "Northern Ireland",
  },
  {
    id: "51",
    type: "Formula 1",
    title: "Formula 1 Armco Magyar Nagydij Grand Prix",
    date: "19 – 21 July 2024",
    location: "Budapest, Hungary",
  },
  {
    id: "52",
    type: "athletics",
    title: "London Diamond League",
    date: "20 July 2024",
    location: "London, UK",
  },
  {
    id: "53",
    type: "Formula E",
    title: "London ePrix Round 15 & 16",
    date: "20 – 21 July 2024",
    location: "London, Great Britain",
  },
  {
    id: "54",
    type: "tennis",
    title: "Hamburg European Open",
    date: "20 – 26 July 2024",
    location: "Hamburg, Germany",
  },
  {
    id: "55",
    type: "multiple sports",
    title: "Olympic Games",
    date: "26 July – 11 August 2024",
    location: "Paris, France",
  },
  {
    id: "56",
    type: "Formula 1",
    title: "Formula 1 Rolex Belgian Grand Prix",
    date: "26 – 28 July 2024",
    location: "Spa-Francorchamps, Belgium",
  },
  {
    id: "57",
    type: "tennis",
    title: "Mubadala Citi DC Open",
    date: "27 July – 4 August 2024",
    location: "Washington D.C., USA",
  },
  {
    id: "58",
    type: "tennis",
    title: "National Bank Open Montreal",
    date: "3 – 12 August 2024",
    location: "Montreal, Canada",
  },
  {
    id: "59",
    type: "motoGP",
    title: "British Grand Prix",
    date: "4 August 2024",
    location: "Silverstone, UK",
  },
  {
    id: "60",
    type: "tennis",
    title: "National Bank Open Toronto",
    date: "4 – 12 August 2024",
    location: "Toronto, Canada",
  },
  {
    id: "61",
    type: "tennis",
    title: "Western & Southern Open",
    date: "11 – 19 August 2024",
    location: "Cincinnati, OH, USA",
  },
  {
    id: "62",
    type: "football",
    title: "Start 2024/2025 Premier League Season",
    date: "17 August 2024",
    location: "UK",
  },
  {
    id: "63",
    type: "motoGP",
    title: "Motorrad Grand Prix von Österreich",
    date: "18 August 2024",
    location: "Spielberg, Austria",
  },
  {
    id: "64",
    type: "golf",
    title: "AIG Women’s Open",
    date: "21 – 25 August 2024",
    location: "St Andrews, Scotland",
  },
  {
    id: "65",
    type: "athletics",
    title: "Lausanne Diamond League",
    date: "22 August 2024",
    location: "Lausanne, Switzerland",
  },
  {
    id: "66",
    type: "Formula 1",
    title: "Formula 1 Dutch Grand Prix",
    date: "23 – 25 August 2024",
    location: "Zandvoort, Netherlands",
  },
  {
    id: "67",
    type: "athletics",
    title: "Silesia Diamond League",
    date: "25 August 2024",
    location: "Silesia, Poland",
  },
  {
    id: "68",
    type: "tennis",
    title: "US Open",
    date: "26 August – 8 September 2024",
    location: "New York, NY, USA",
  },
  {
    id: "69",
    type: "multiple sports",
    title: "Paralympic Games",
    date: "28 August – 8 September 2024",
    location: "Paris, France",
  },
  {
    id: "70",
    type: "athletics",
    title: "Rome Diamond League",
    date: "30 August 2024",
    location: "Rome, Italy",
  },
  {
    id: "71",
    type: "Formula 1",
    title: "Formula 1 Gran Premio Heineken d’Italia",
    date: "30 August – 1 September 2024",
    location: "Monza, Italy",
  },
  {
    id: "72",
    type: "football",
    title: "FIFA U-20 Women’s World Cup 2024",
    date: "31 August – 22 September 2024",
    location: "Colombia",
  },
];

export const eventCategories = [
  "football",
  "tennis",
  "athletics",
  "Formula 1",
  "motoGP",
  "rugby",
  "rhythmic gymnastics",
  "horse racing",
  "golf",
  "cricket",
  "baseball",
  "basketball",
  "multiple sports",
];
