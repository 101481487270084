import { theme } from "@chakra-ui/pro-theme";
import { extendTheme } from "@chakra-ui/react";

const countryColorGradient =
  "linear-gradient(45deg, #FF0000, #FFFFFF, #0052CC, #FFFFFF, #00A651, #F7F7F7)";

const proTheme = extendTheme(theme);
const extendedConfig = {
  colors: { ...proTheme.colors, brand: proTheme.colors.teal },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
      variants: {
        countryGradient: (props: any) => ({
          bg: countryColorGradient,
          color: "black",
          fontWeight: "black",
          fontSize: "20px",
          _hover: {
            bg: countryColorGradient,
          },
          _active: {
            bg: countryColorGradient,
          },
        }),
        categoryButton: (props: any) => ({
          bg: "linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(1, 1, 1, 0.8));",
          color: "white",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3);",
          fontWeight: "black",
          fontSize: "20px",
          _hover: {
            boxSshadow: "4px 4px 10px rgba(0, 0, 0, 0.3);",
          },
          _active: {
            bg: "green",
          },
        }),
      },
    },
    Text: {
      variants: {
        error: (props: any) => ({
          color: "red.500",
          fontWeight: "bold",
          fontSize: "16px",
        }),
      },
    },
  },
};

export const countryStatsTheme = extendTheme(extendedConfig, proTheme);
