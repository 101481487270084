import {
  Box,
  Button,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  Icon,
  Spinner,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { FaCheck, FaCheckDouble } from "react-icons/fa6";
import PageLayout from "../../Layout/PageLayout";

export interface NationFiApplication {
  contractAddress: string;
  telegramContactInformation: string;
  coinName: string;
  tickerSymbol: string;
  currentMarketCap: string;
  dexscreener?: string;
  telegram?: string;
  twitter?: string;
  website?: string;
}

const validateNationFiApplicationPayload = (
  application: NationFiApplication,
) => {
  return (
    application &&
    application.contractAddress &&
    application.contractAddress.length > 0 &&
    application.telegramContactInformation &&
    application.telegramContactInformation.length > 0 &&
    application.coinName &&
    application.coinName.length > 0 &&
    application.tickerSymbol &&
    application.tickerSymbol.length > 0 &&
    application.currentMarketCap &&
    application.currentMarketCap.length > 0
  );
};

export default function ApplicationIndex() {
  const [application, setApplication] = useState<NationFiApplication>({
    contractAddress: "",
    telegramContactInformation: "",
    coinName: "",
    tickerSymbol: "",
    currentMarketCap: "",
    dexscreener: "",
    telegram: "",
    twitter: "",
    website: "",
  });

  const [showError, setShowError] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (!validateNationFiApplicationPayload(application)) {
      setShowError(true);
      return;
    }
    setShowError(false);
    setLoading(true);

    axios
      .post(
        "https://backend.nationfisolana.com/nationfi-application",
        application,
      )
      .then(() => {
        setLoading(false);
        setHasApplied(true);
      })
      .catch(() => {
        setLoading(false);
        setShowError(true);
      });
  };

  if (loading) {
    return (
      <PageLayout>
        <Box textAlign="center" mt={20}>
          <Spinner />
        </Box>
      </PageLayout>
    );
  }

  if (hasApplied) {
    return (
      <PageLayout>
        <Box textAlign="center" mt={20}>
          <Icon as={FaCheckDouble} boxSize="10" color="green" />
          <Heading size="xs" mt={6}>
            Application Submitted
          </Heading>
          <Text mt={4}>
            Your application has been successfully sent. We will review it and
            get back to you shortly or just simply list your coin!
          </Text>
        </Box>
      </PageLayout>
    );
  }

  const newCoinRequirements = [
    "Minimum Age: 1 Week",
    "Minimum of $10K Volume in 24 Hours",
    "Minimum of 100 Holders",
    "Minimum of $50K Market Cap",
    "Minimum of 150 Members in Telegram Community",
  ];

  const ctoRequirements = [
    "Minimum of 5 Days Since CTO",
    "Minimum of 300 Holders",
    "Minimum of $75K Market Cap",
  ];

  const generalRequirements = [
    "Up to date information on Dexscreener",
    "Active Socials (Website, Telegram, X)",
    "Telegram Chat Activity (Min. 100+ Messages everyday)",
  ];

  return (
    <PageLayout>
      <SimpleGrid columns={{ base: 1, md: 3 }}>
        <VStack alignItems="start">
          <Heading size="2xs" textAlign="left" mt={20}>
            General Requirements
          </Heading>
          <VStack alignItems="start" mt={2}>
            {generalRequirements.map((e) => {
              return (
                <HStack spacing={1}>
                  <Icon as={FaCheck} color="green" />
                  <Text>{e}</Text>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
        <VStack alignItems="start">
          <Heading size="2xs" textAlign="left" mt={20}>
            Listing Requirements For New Coins
          </Heading>
          <VStack alignItems="start" mt={2}>
            {newCoinRequirements.map((e) => {
              return (
                <HStack spacing={1}>
                  <Icon as={FaCheck} color="green" />
                  <Text>{e}</Text>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
        <VStack alignItems="start">
          <Heading size="2xs" textAlign="left" mt={20}>
            Listing Requirements for CTO's
          </Heading>
          <VStack alignItems="start" mt={2}>
            {ctoRequirements.map((e) => {
              return (
                <HStack spacing={1}>
                  <Icon as={FaCheck} color="green" />
                  <Text>{e}</Text>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      </SimpleGrid>

      <Heading size="xs" textAlign="left" mt={20}>
        Apply For Listing
      </Heading>
      <VStack width="full" mt={3} spacing={6} alignItems="start">
        <VStack width="full" alignItems="start" spacing={0}>
          <FormLabel>Contract Address*</FormLabel>
          <Input
            aria-label="Contract Address"
            defaultValue={application.contractAddress}
            required
            name="contractAddress"
            type="string"
            placeholder="Contract Address"
            onChange={(e) => {
              setApplication((prevApplication) => ({
                ...prevApplication,
                contractAddress: e.target.value,
              }));
            }}
          />
        </VStack>
        <VStack width="full" alignItems="start" spacing={0}>
          <FormLabel>Telegram Contact Information of Submitter*</FormLabel>
          <Input
            aria-label="Contact Information"
            defaultValue={application.telegramContactInformation}
            required
            name="telegramContactInformation"
            type="string"
            placeholder="Contact Information"
            onChange={(e) => {
              setApplication((prevApplication) => ({
                ...prevApplication,
                telegramContactInformation: e.target.value,
              }));
            }}
          />
        </VStack>
        <VStack width="full" alignItems="start" spacing={0}>
          <FormLabel>Coin Name*</FormLabel>
          <Input
            aria-label="Coin Name"
            required
            defaultValue={application.coinName}
            name="coinName"
            type="string"
            placeholder="Coin Name"
            onChange={(e) => {
              setApplication((prevApplication) => ({
                ...prevApplication,
                coinName: e.target.value,
              }));
            }}
          />
        </VStack>
        <VStack width="full" alignItems="start" spacing={0}>
          <FormLabel>Coin Symbol*</FormLabel>
          <Input
            aria-label="Coin Symbol"
            defaultValue={application.tickerSymbol}
            required
            name="tickerSymbol"
            type="string"
            placeholder="Coin Symbol"
            onChange={(e) => {
              setApplication((prevApplication) => ({
                ...prevApplication,
                tickerSymbol: e.target.value,
              }));
            }}
          />
        </VStack>
        <VStack width="full" alignItems="start" spacing={0}>
          <FormLabel>Current Market Cap*</FormLabel>
          <Input
            aria-label="Current Market Cap"
            required
            defaultValue={application.currentMarketCap}
            name="currentMarketCap"
            type="string"
            placeholder="Current Market Cap"
            onChange={(e) => {
              setApplication((prevApplication) => ({
                ...prevApplication,
                currentMarketCap: e.target.value,
              }));
            }}
          />
        </VStack>
        <VStack width="full" alignItems="start" spacing={0}>
          <FormLabel>Dexscreener Link*</FormLabel>
          <Input
            aria-label="Dexscreener Link"
            required
            defaultValue={application.dexscreener}
            name="dexscreener"
            type="string"
            placeholder="Dexscreener Link"
            onChange={(e) => {
              setApplication((prevApplication) => ({
                ...prevApplication,
                dexscreener: e.target.value,
              }));
            }}
          />
        </VStack>
        <VStack width="full" alignItems="start" spacing={0}>
          <FormLabel>Coin's Telegram Link*</FormLabel>
          <Input
            aria-label="Coin's Telegram Link"
            defaultValue={application.telegram}
            required
            name="telegram"
            type="string"
            placeholder="Coin's Telegram Link"
            onChange={(e) => {
              setApplication((prevApplication) => ({
                ...prevApplication,
                telegram: e.target.value,
              }));
            }}
          />
        </VStack>
        <VStack width="full" alignItems="start" spacing={0}>
          <FormLabel>Coin's Twitter Link*</FormLabel>
          <Input
            aria-label="Coin's Twitter Link*"
            defaultValue={application.twitter}
            required
            name="twitter"
            type="string"
            placeholder="Coin's Twitter Link"
            onChange={(e) => {
              setApplication((prevApplication) => ({
                ...prevApplication,
                twitter: e.target.value,
              }));
            }}
          />
        </VStack>
        <VStack width="full" alignItems="start" spacing={0}>
          <FormLabel>Coin's Website*</FormLabel>
          <Input
            aria-label="Coin's Website Link"
            defaultValue={application.website}
            required
            name="website"
            type="string"
            placeholder="Coin's Website Link"
            onChange={(e) => {
              setApplication((prevApplication) => ({
                ...prevApplication,
                website: e.target.value,
              }));
            }}
          />
        </VStack>
        {showError && (
          <Text variant="error">
            Please check your input and make sure you have filled out all
            required fields.
          </Text>
        )}
        <Button
          type="submit"
          width="full"
          variant="countryGradient"
          onClick={() => {
            onSubmit();
          }}
          mb={4}
        >
          Submit Application
        </Button>
      </VStack>
    </PageLayout>
  );
}
