import { Box, Button, HStack, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import PageLayout from "../../Layout/PageLayout";
import { useState } from "react";
import { Pair } from "../../apis/dexscreener";
import { Stat } from "../../Components/Stat";
import { fnum } from "./CoinsTable";
import CoinsTable from "./CoinsTable";
import { CATEGORIES_AND_TOKENS, CategoryAndTokens } from "../../apis/coins";
import { useNavigate } from "react-router-dom";

export default function HomePageIndex() {
  const navigate = useNavigate();

  const [coinsLoading, setCoinsLoading] = useState(true);
  const [categoryCoinsCount, setCategoryCoinsCount] = useState(0);
  const [categoryCoinsMarketCap, setCategoryCoinsMarketCap] = useState(0);
  const [categoryCoins24HVolume, setCategoryCoins24HVolume] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(
    CATEGORIES_AND_TOKENS[0],
  );

  const onNewTokenPair = (pair: Pair) => {
    setCategoryCoinsCount((prevCount) => prevCount + 1);
    setCategoryCoinsMarketCap((prevMarketCap) => prevMarketCap + pair.fdv);
    setCategoryCoins24HVolume(
      (prev24HVolume) => prev24HVolume + pair.volume.h24,
    );
  };

  const onChangeCategory = (e: CategoryAndTokens) => {
    setCategoryCoinsCount(0);
    setCategoryCoinsMarketCap(0);
    setCategoryCoins24HVolume(0);
    setSelectedCategory(e);
  };

  return (
    <PageLayout>
      <HStack width="full">
        <Box as="section" py={{ base: "4", md: "8" }} width="full">
          <SimpleGrid columns={{ base: 1, md: 4 }} gap={{ base: "5", md: "6" }}>
            <Stat
              label={`Category ${selectedCategory.categoryName}`}
              value={categoryCoinsCount.toString()}
              loading={coinsLoading}
            />
            <Stat
              label="Total Market Cap"
              value={`$${fnum(categoryCoinsMarketCap)}`}
              loading={coinsLoading}
            />
            <Stat
              label="Total Volume (24H)"
              value={`$${fnum(categoryCoins24HVolume)}`}
              loading={coinsLoading}
            />
            <Stat
              label="Upcoming Events And More"
              value="72 Listed"
              loading={false}
              onClick={() => {
                navigate("/events");
              }}
              clickText="See Events"
            />
          </SimpleGrid>
        </Box>
      </HStack>
      <Box
        px={{ base: "4", md: "6" }}
        py={{ base: "5", md: "6" }}
        bg="bg.surface"
        borderRadius="lg"
        boxShadow="sm"
      >
        <HStack
          justifyContent="center"
          alignItems="center"
          gap={4}
          flexWrap="wrap"
        >
          {CATEGORIES_AND_TOKENS.map((e) => {
            return (
              <Button
                minWidth="100px"
                variant="categoryButton"
                isActive={selectedCategory.categoryName === e.categoryName}
                isDisabled={selectedCategory.categoryName === e.categoryName}
                onClick={() => {
                  onChangeCategory(e);
                }}
              >
                {e.categoryName}
              </Button>
            );
          })}
        </HStack>
      </Box>
      <Stack spacing="5" mt={10}>
        <CoinsTable
          onAllLoaded={() => {
            setCoinsLoading(false);
          }}
          onNewTokenPair={onNewTokenPair}
          category={selectedCategory}
        />
      </Stack>
      <Text fontSize="xs" mt={10} mb={10}>
        NationFi aggregates onchain data. NationFiSolana.com or the team doesn’t
        endorse any of the tokens. Do your own research before investing.
      </Text>
    </PageLayout>
  );
}
