import { HStack, IconButton, Image, useDisclosure } from "@chakra-ui/react";
import { FaInfo, FaSquareXTwitter, FaTelegram } from "react-icons/fa6";
import { ALL_COINS } from "../apis/coins";
import { Pair } from "../apis/dexscreener";
import { TbWorld } from "react-icons/tb";
import DextoolsLogo from "../Assets/dextools_logo.webp";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Text,
} from "@chakra-ui/react";
import ReactGa from "react-ga";

export type FixedSocialsDisplayPropsType = {
  twitter?: string;
  telegram?: string;
};

export default function FixedSocialsDisplay({
  twitter,
  telegram,
}: FixedSocialsDisplayPropsType) {
  const onTwitterClick = () => {
    if (!twitter) {
      return;
    }

    window.open(twitter, "_blank");
  };

  const onTelegramClick = () => {
    if (!telegram) {
      return;
    }

    window.open(telegram, "_blank");
  };

  return (
    <>
      <HStack>
        {twitter && (
          <IconButton
            icon={<FaSquareXTwitter />}
            aria-label="Twitter"
            bg="black"
            color="white"
            boxSize="6"
            onClick={onTwitterClick}
          />
        )}
        {telegram && (
          <IconButton
            icon={<FaTelegram />}
            aria-label="Telegram"
            bg="black"
            color="white"
            boxSize="6"
            onClick={onTelegramClick}
          />
        )}
      </HStack>
    </>
  );
}

type SocialsDisplayProps = {
  socials: {
    type: string;
    url: string;
  }[];
  pair?: Pair;
  onShowChart?: (pairPk: string) => void;
};

export function SocialsDisplay({
  socials,
  pair,
  onShowChart,
}: SocialsDisplayProps) {
  const twitter = socials && socials.find((e) => e.type === "twitter");
  const telegram = socials && socials.find((e) => e.type === "telegram");
  const dexToolsExists = pair && pair.url;

  const showPopover = useDisclosure();

  const data = ALL_COINS.find((e) => e.ca === pair?.baseToken.address);

  const website =
    data && data.website
      ? data.website
      : pair && pair.info && pair.info.websites && pair.info.websites.length > 0
        ? pair.info.websites[0].url ?? undefined
        : undefined;

  const isDataDifferent =
    data &&
    ((data.twitter && data.twitter !== twitter?.url) ||
      (data.telegram && data.telegram !== telegram?.url));

  const onTwitterClick = () => {
    if (!twitter) {
      return;
    }

    const url = data && data.twitter ? data.twitter : twitter.url;

    ReactGa.event({
      category: "twitter_clicks",
      action: pair?.baseToken.name ?? url,
      label: `Twitter visited`,
      dimension1: url, // the URL of the clicked link
      transport: "beacon",
    });

    window.open(url, "_blank");
  };

  const onTelegramClick = () => {
    if (!telegram) {
      return;
    }

    const url = data && data.telegram ? data.telegram : telegram.url;

    ReactGa.event({
      category: "telegram_clicks",
      action: pair?.baseToken.name ?? url,
      label: `Dextools visited`,
      dimension1: url, // the URL of the clicked link
      transport: "beacon",
    });

    window.open(data && data.telegram ? data.telegram : telegram.url, "_blank");
  };

  const onWebsiteClick = () => {
    if (!website) {
      return;
    }

    ReactGa.event({
      category: "website_clicks",
      action: pair?.baseToken.name ?? website,
      label: `Website visited`,
      dimension1: website, // the URL of the clicked link
      transport: "beacon",
    });

    window.open(website, "_blank");
  };

  const onDextools = () => {
    if (!dexToolsExists) {
      return;
    }

    ReactGa.event({
      category: "dextools_clicks",
      action: pair?.baseToken.name ?? website,
      label: `Dextools visited`,
      dimension1: pair.url, // the URL of the clicked link
      transport: "beacon",
    });

    window.open(pair.url, "_blank");
  };

  return (
    <>
      <HStack>
        {dexToolsExists && (
          <Image
            src={DextoolsLogo}
            cursor="pointer"
            borderRadius="10px"
            onClick={onDextools}
            width="32px"
            height="32px"
            mb={2}
          />
        )}
        {twitter && (
          <IconButton
            icon={<FaSquareXTwitter />}
            aria-label="Twitter"
            bg="black"
            color="white"
            boxSize="6"
            onClick={onTwitterClick}
          />
        )}
        {telegram && (
          <IconButton
            icon={<FaTelegram />}
            aria-label="Telegram"
            bg="black"
            onClick={onTelegramClick}
            color="white"
            boxSize="6"
          />
        )}
        {website && (
          <IconButton
            icon={<TbWorld />}
            aria-label="Website"
            bg="black"
            onClick={onWebsiteClick}
            color="white"
            boxSize="6"
          />
        )}

        {isDataDifferent && (
          <Popover isOpen={showPopover.isOpen} onClose={showPopover.onClose}>
            <PopoverTrigger>
              <IconButton
                icon={<FaInfo />}
                aria-label="Info"
                onClick={showPopover.onOpen}
                bg="transparent"
                color="blue"
                boxSize="6"
              />
            </PopoverTrigger>
            <PopoverContent p={3}>
              <PopoverArrow />
              <Text>Links are newer than from dexscreener.</Text>
            </PopoverContent>
          </Popover>
        )}
      </HStack>
      {onShowChart && pair && pair.dexId && (
        <Text
          textAlign="left"
          fontWeight="bold"
          color="blue"
          cursor="pointer"
          onClick={() => {
            onShowChart(pair.pairAddress);
          }}
        >
          Show Chart
        </Text>
      )}
    </>
  );
}
