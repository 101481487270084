import {
  Avatar,
  Box,
  Divider,
  HStack,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SocialsDisplay } from "../../Components/SocialsDisplay";
import { dexscreener, Pair } from "../../apis/dexscreener";
import { CategoryAndTokens } from "../../apis/coins";
import DexscreenerPairEmbed from "../../Components/DexscreenerPairEmbed";
import { BaseDrawer } from "../../Components/BaseDrawer";

type CoinsTableProps = {
  onNewTokenPair: (pair: Pair) => void;
  onAllLoaded: () => void;
  category: CategoryAndTokens;
};

export function fnum(x: number) {
  if (x < 10000) {
    return x.toFixed(2);
  } else if (x < 1000000) {
    return (x / 1000).toFixed(2) + "K";
  } else if (x < 1000000000) {
    return (x / 1000000).toFixed(2) + "M";
  } else if (x < 1000000000000) {
    return (x / 1000000000).toFixed(2) + "B";
  }
  return x;
}

export default function CoinsTable({
  onNewTokenPair,
  onAllLoaded,
  category,
}: CoinsTableProps) {
  const [tokenInfos, setTokenInfos] = useState<Pair[]>([]);
  const [coinsLoading, setCoinsLoading] = useState(true);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [currentChartPairId, setCurrentChartPairId] = useState("");

  useEffect(() => {
    const controller = new AbortController();

    async function loadCoinPairs() {
      setTokenInfos([]);
      setCoinsLoading(true);

      const dexApi = dexscreener();
      let tempTokenInfos = [];

      for (const coinCa of category.tokens.map((e) => e.ca)) {
        if (controller.signal.aborted) {
          setTokenInfos([]);
          return;
        }

        const coinData = await dexApi.getTokenInfo(coinCa);
        if (!coinData) {
          continue;
        }

        tempTokenInfos.push(coinData);
        tempTokenInfos.sort((a, b) => b.fdv - a.fdv); // sort array here

        if (controller.signal.aborted) {
          setTokenInfos([]);
          return;
        }

        setTokenInfos(tempTokenInfos); // update state here
        onNewTokenPair(coinData);
      }

      setCoinsLoading(false);
      onAllLoaded();
    }

    loadCoinPairs();

    return () => {
      controller.abort();
    };
  }, [category]);

  const getPriceChangeColor = (priceChange: number) => {
    if (priceChange < 0) {
      return "red";
    }

    if (priceChange > 0) {
      return "green";
    }

    return "black";
  };

  return (
    <>
      <HStack>
        <Box>
          <Text textStyle="xl" fontWeight="bold">
            {category.categoryName}
          </Text>
          <Text color="fg.muted" textStyle="sm">
            Overview and rankings of {category.categoryName} Category
          </Text>
          <Text color="fg.muted" textStyle="sm" mt={4}>
            {category.description}
          </Text>
        </Box>
        {coinsLoading && <Spinner />}
      </HStack>
      <Divider />
      <Table>
        <Thead>
          <Tr>
            <Th>Coin</Th>
            {!isMobile && <Th>Market Cap</Th>}
            {!isMobile && <Th>Liquidity</Th>}
            {!isMobile && <Th>Price 24H</Th>}
            {!isMobile && <Th></Th>}
          </Tr>
        </Thead>
        <Tbody>
          {tokenInfos.map((coinPairInfo, i) => (
            <Tr key={i}>
              <Td>
                <HStack
                  spacing={{ base: "12", md: "3" }}
                  justifyContent={{ base: "start", md: "unset" }}
                >
                  <Avatar
                    name={coinPairInfo.baseToken.name}
                    ignoreFallback
                    src={`${coinPairInfo.info?.imageUrl}?size=lg&key=82535f`}
                    boxSize="10"
                  />
                  <VStack
                    alignItems={{ base: "start", md: "unset" }}
                    textAlign={{ base: "left", md: "unset" }}
                  >
                    <HStack>
                      <Text fontWeight="bold">{i + 1}.</Text>
                      <Text fontWeight="bold">
                        {coinPairInfo.baseToken.name}
                      </Text>
                    </HStack>
                    {isMobile && (
                      <Text fontWeight="bold" fontSize="sm">
                        Market Cap: ${fnum(coinPairInfo.fdv)}
                      </Text>
                    )}
                    {isMobile && (
                      <Text fontWeight="bold" fontSize="sm">
                        Liquidity: ${fnum(coinPairInfo.liquidity.usd)}
                      </Text>
                    )}
                    {isMobile && (
                      <VStack alignItems="start">
                        <Text
                          color={getPriceChangeColor(
                            coinPairInfo.priceChange.h24,
                          )}
                          fontWeight="bold"
                          fontSize="sm"
                        >
                          24H: {coinPairInfo.priceChange.h24}%
                        </Text>
                        <SocialsDisplay
                          socials={coinPairInfo.info?.socials}
                          pair={coinPairInfo}
                          onShowChart={setCurrentChartPairId}
                        />
                      </VStack>
                    )}
                  </VStack>
                </HStack>
              </Td>
              {!isMobile && (
                <Td padding={3}>
                  <Stack>
                    <Text></Text>
                    <Text fontWeight="bold" fontSize="md">
                      ${fnum(coinPairInfo.fdv)}
                    </Text>
                  </Stack>
                </Td>
              )}
              {!isMobile && (
                <Td>
                  <Text fontWeight="bold" fontSize="md">
                    {`$${fnum(coinPairInfo.liquidity.usd)}`}
                  </Text>
                </Td>
              )}
              {!isMobile && (
                <Td
                  color={getPriceChangeColor(coinPairInfo.priceChange.h24)}
                  fontWeight="bold"
                  fontSize="md"
                >
                  {coinPairInfo.priceChange.h24}%
                </Td>
              )}
              {!isMobile && (
                <Td>
                  <SocialsDisplay
                    socials={coinPairInfo.info?.socials}
                    pair={coinPairInfo}
                    onShowChart={setCurrentChartPairId}
                  />
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {currentChartPairId.length > 0 && (
        <BaseDrawer
          isOpen={currentChartPairId.length > 0}
          placement="bottom"
          size="xl"
          onClose={() => {
            setCurrentChartPairId("");
          }}
          header={undefined}
          showCloseButton
        >
          <DexscreenerPairEmbed pairId={currentChartPairId} />
        </BaseDrawer>
      )}
    </>
  );
}
