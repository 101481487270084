export type TokenInfo = {
  ca: string;
  name: string;
  twitter?: string;
  telegram?: string;
  website?: string;
  tiktok?: string;
  categories: string[];
};

export type CategoryAndTokens = {
  categoryName: string;
  description: string;
  tokens: TokenInfo[];
};

export const ALL_COINS: TokenInfo[] = [
  {
    ca: "69kdRLyP5DTRkpHraaSZAQbWmAwzF9guKjZfzMXzcbAs",
    name: "American Coin",
    twitter: "https://twitter.com/USA_ON_CHAIN",
    telegram: "https://t.me/AMERICACOINUSA",
    categories: ["countries", "olympics", "copa"],
  },
  {
    ca: "E2BGnzHdJNUBtAVR7EyQMuEMHqgv65JL8J9ZyqyXUVvA",
    name: "China Coin",
    twitter: "https://twitter.com/CHINAonsolana",
    website: "https://chinanamba1.com/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "F9mv7XXbrXZb1sP2JUoswbCB3WHQM4QGMFDTVfnRZMnP",
    name: "France Coin",
    twitter: "https://twitter.com/franceCoinSol",
    telegram: "https://t.me/francecoinPortal",
    website: "https://www.francecoin.vip/",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "9XRpjZjhJPeWtUymiEWn3FW7uAnMeQca14ucTWWWyP2g",
    name: "ArgentinaCoin",
    twitter: "https://twitter.com/ArgentinaOnSol",
    telegram: "https://t.me/argentinacoin_sol",
    website: "https://www.argentinacoin.vip/",
    categories: ["countries", "olympics", "copa"],
  },
  {
    ca: "52DfsNknorxogkjqecCTT3Vk2pUwZ3eMnsYKVm4z3yWy",
    name: "Germany ",
    twitter: "https://twitter.com/GermanyOnSol",
    telegram: "https://t.me/+wSKrEf0ocS5lZTRh",
    website: "https://germanyonsol.com/",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "6tCpSeeaatDCo4Ue14VhkwM17YjsPCgcxGoh1ZZWwXVj",
    name: "Tuvaluan coin",
    twitter: "https://x.com/tuvaluonso",
    telegram: "https://t.me/tuvaluonsolana",
    categories: ["countries", "olympics"],
  },
  {
    ca: "4XQvdipJBdrb5hUgUrbZPPFmp6BCav41n55dc7KDYW3m",
    name: "ENGLAND",
    twitter: "https://x.com/eng_on_sol?s=21",
    telegram: "https://t.me/ENG_ON_SOL",
    website: "https://www.englandonsol.site/",
    categories: ["countries", "euros"],
  },
  {
    ca: "BSHanq7NmdY6j8u5YE9A3SUygj1bhavFqb73vadspkL3",
    name: "United Arab Emirates Coin",
    twitter: "https://twitter.com/UnitedArabCoin",
    telegram: "https://t.me/UAEonsol",
    categories: ["countries", "olympics"],
  },
  {
    ca: "GuVoE2qAS3DHaAGSeuZfBkbLjFXfP46DFbogbrVJNHfN",
    name: "Italian Coin",
    twitter: "https://twitter.com/italiancoin_sol",
    telegram: "https://t.me/italiancoinportal",
    website: "https://itacoin.io",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "EHCwJQi8dSpZfKm4LJypzozEj5vAN7pESRXJGpESKMfJ",
    name: "Japan Coin 🇯🇵",
    twitter: "https://x.com/japancoinsolana",
    telegram: "https://t.me/Japan_Coin_Sol",
    categories: ["countries", "olympics"],
  },
  {
    ca: "HhAcs9JjcveM45FG2Zi3JcWAomra3QezZ22mVvr7zPSE",
    name: "BRASIL COIN",
    twitter: "https://twitter.com/BrasilCoinSOL",
    telegram: "https://t.me/BrasilCoin",
    website: "https://brasilcoin.xyz/",
    categories: ["countries", "olympics", "copa"],
  },
  {
    ca: "8uz7r3yQq8xnwBfWCoQGAftyYKgUz8wMQWod884nerf4",
    name: "SpainCoin",
    twitter: "https://x.com/spainonsolcto",
    telegram: "https://t.me/+MVAwVXt6iUk2M2M0",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "6tWuipcDv4CiHtXid7JctDbtLLA1VdkTwSDupK6UxzJL",
    name: "South Korea Coin 🇰🇷",
    twitter: "https://twitter.com/SOUTHKOREACOIN",
    telegram: "https://t.me/southkoreacoin",
    categories: ["countries", "olympics"],
  },
  {
    ca: "36JQHwN2EgP9sKkCHn7axdpBxyLT8JUfiCnqHoy5r83R",
    name: "Russia",
    twitter: "https://twitter.com/RussiaSolana",
    telegram: "https://t.me/russia_sol",
    website: "http://russiacoinsol.com/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "Avp2VDgnQqxsnrjtq3ynNhKCfWGEGj1PmGLY5ZmgonjH",
    name: "Saudi Arabia Coin",
    twitter: "https://twitter.com/saudiarabiacoin",
    telegram: "https://t.me/SAUDI_on_SOL",
    website: "https://saudiarabiacoin.net/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "Eb6AGbkhow75fgJshgZEpZN1S3P11GdQRXEWX7pvzka3",
    name: "Indian Coin",
    twitter: "https://twitter.com/IndianCoinSol",
    telegram: "https://t.me/indiancoinsolana",
    website: "https://www.indiacoinsol.com/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "DH7HuYR54FNp49isrus6AdqREuQfWYV6qaVPg8xVnZQ9",
    name: "Netherlands Coin",
    twitter: "https://twitter.com/NetherlandsSol",
    telegram: "https://t.me/+7MAYRSg7v6MwMDdh",
    website: "https://netherlandsonsol.com/",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "BT2apS5umybEthwy6xP5PfgNDw3ykMyxirY5XxZ7H654",
    name: "PROTUGAL",
    twitter: "https://twitter.com/protugalsol",
    telegram: "https://t.me/PROTUGALL",
    website: "https://protugal.co/",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "8nSQjm6SotxGDGkgBccKchRufynu5KRPBGkBEN4dDk2n",
    name: "Australian Coin",
    twitter: "https://x.com/AustralianCoin_?t=YtS29IvYDSJbcjELjNT47g&s=09",
    telegram: "https://t.me/+iOQBNV544B0zMjQ1",
    categories: ["countries", "olympics"],
  },
  {
    ca: "Ehk7QXFVqHkyqSpcfmUTCm5mJ9wE5b2axkC6Emcv7Qh1",
    name: "Mexico Coin",
    twitter: "https://twitter.com/MEXICO_COIN",
    website: "https://Mexicosol.xyz",
    telegram: "t.me/mexicosol",
    categories: ["countries", "olympics", "copa"],
  },
  {
    ca: "EfAeGjyyPSEpsxHHFT7MuhRZTNdm6vqf1zNNAerR7JWG",
    name: "Croatia Coin",
    twitter: "https://twitter.com/CroatiaCoin",
    telegram: "https://t.me/CroatiaCoin",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "3zSgyhUqLvDtVnsMnSia6AwxWUE9w3jVMYVV56zzskPG",
    name: "Canadian Coin",
    twitter: "https://x.com/CanadianonSol",
    telegram: "https://t.me/canadacoinportal",
    website: "https://canadiancoinonsol.ca",
    tiktok: "https://www.tiktok.com/@canadiancoinsol.ca",
    categories: ["countries", "olympics", "copa"],
  },
  {
    ca: "2A2AzWAw9GEJ9tXoe6gft4jC2RL6LFzbj6DPsbmhjZ2m",
    name: "Switzerland ",
    twitter: "https://twitter.com/SwissTokenSol",
    telegram: "https://t.me/SwissTokenOnSOL",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "GPJRKb25amJfG1by8WwdMqCT525iZku4fgaFBYAouvin",
    name: "ISRAEL",
    twitter: "https://twitter.com/IsraeliCoinSol",
    telegram: "https://t.me/israelsolanaportal",
    categories: ["countries", "olympics"],
  },
  {
    ca: "2QnSmbcbrfoxfCvr9iQpn4bWpBkzDvwGm47PjkZGU3HS",
    name: "Hong Kong Coin",
    twitter: "https://x.com/HongKongOnSol",
    telegram: "https://t.me/HongKongSol",
    website: "https://www.hongkongcoin.site",
    categories: ["countries", "olympics"],
  },
  {
    ca: "2aKb8XmBMthbWNAT5R24Fjw6ENBKEZjNuPFw4hh7Z37W",
    name: "UK Coin ",
    categories: ["countries"],
  },
  {
    ca: "6jML6Q2pQD3jPQbsvJmdzRwGta7gWguB4evtgdG9GAtf",
    name: "Ukraine",
    twitter: "https://twitter.com/ukrainaonsol",
    telegram: "https://t.me/ukrainecoinonsol",
    website: "https://ukrainecoin.xyz/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "Ct2RaUQueSpE2J5y99BwgGu3RkbrdiHSvgPRrr3pMxg2",
    name: "Turkiye",
    twitter: "https://twitter.com/cointurkiyesol",
    telegram: "https://t.me/turkiyecoinsol",
    website: "https://turkiyecoin.site",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "EVv4WZw2XNHHGRQ1dUcckA9hDLfoaARjshAXrRUbaEyA",
    name: "Austria Coin",
    twitter: "https://x.com/austriaonsol",
    telegram: "https://t.me/austriaonsol",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "5TdrUp28ngLyDhTU3rUv7qL4oNkGEeVANN7TUPnFHZ9H",
    name: "Irish Coin",
    twitter: "https://twitter.com/IrishCoinSol",
    telegram: "https://t.me/IrishCoinSol",
    categories: ["countries", "olympics"],
  },
  {
    ca: "AHfWYdXqcMVkcqpXjsxDSddfLUcgFEu8bVYzCAX9DhFy",
    name: "Nigeria ",
    twitter: "https://twitter.com/naijaonsol ",
    telegram: "https://t.me/Naijaonsol",
    website: "https://naijaonsol.com",
    categories: ["countries", "olympics"],
  },
  {
    ca: "58cx3cN6UiBPB6kiXXcSaLZGE3Eu9XUJpiZ2XH8SBKtS",
    name: "Vatican Coin",
    twitter: "https://twitter.com/VaticancoinSOL",
    telegram: "https://t.me/vaticancoinsol",
    categories: ["countries"],
  },
  {
    ca: "8xxFnmiCSK4cVLMvPdcc4CtxvzPRnmtrrTZfXhiGPT4n",
    name: "Jamaica",
    twitter: "http://x.com/jamaica_coin",
    telegram: "https://t.me/jamaicasolana",
    website: "http://jamcoin.xyz",
    categories: ["countries", "olympics", "copa"],
  },
  {
    ca: "5GGEnfRKKBqGMhq6KtPAMADPAxQnN9FdU5YHPsMarZDT",
    name: "Polish Coin",
    twitter: "https://twitter.com/polish_coin_sol",
    telegram: "https://t.me/polish_coin_sol",
    website: "https://www.polishcoin.fun/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "BVAt1aqR6CVCxFseVYhR61WNdg1RXrj2ThPdtanJ45Co",
    name: "Swedish Coin",
    twitter: "https://twitter.com/SwedishCoin",
    telegram: "https://t.me/swedenonsol",
    website: "https://swedencoin.lol/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "5hq7BFmjaX3wrH4MJMcr7s7XyvzvM6Hyeo8Cw5Hiw4kN",
    name: "El Salvador",
    twitter: "https://twitter.com/Sol_elsalvador",
    telegram: "https://t.me/el_salvadorsol",
    categories: ["countries", "olympics"],
  },
  {
    ca: "2GEwopJhqVu7ZpzpjSQa2E5pSDyGhJADGRcnzWaaD9EG",
    name: "Japanese Coin",
    twitter: "https://twitter.com/yensolana",
    telegram: "https://t.me/JapaneseCoin",
    website: "https://japanesecoin.site/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "9PeGEcSeVnPFE9SRHA1dFvKGEEyZnAbzRQjhkumKd7E6",
    name: "Pinoy Coin",
    twitter: "https://twitter.com/PinoyCoinSol",
    telegram: "https://t.me/pinoycoinsol",
    website: "https://pinoycoin.xyz/",
    categories: ["countries"],
  },
  {
    ca: "9NQTAxdWAV8moypBkx6MtehRehAmeoKUK5YcJvkP4v6g",
    name: "New Zealand",
    twitter: "https://twitter.com/NewZealandSol",
    telegram: "https://t.me/NZealandcoin",
    categories: ["countries", "olympics"],
  },
  {
    ca: "D6MhCCin6UaHGq3fJZgK7NWZH9bmWvmJ3Bgmsp9t7QQZ",
    name: "CUBA COIN",
    twitter: "https://twitter.com/CubaonSol",
    telegram: "https://t.me/+y4nfT2BkB4xlYzEx",
    categories: ["countries", "olympics"],
  },
  {
    ca: "3naigHjGia2cSKpoawyLEYb4xZeG1wQ2yUmtJbCxxJkH",
    name: "AlbanianCoin",
    twitter: "https://twitter.com/albaniancoin?s=21",
    telegram: "https://t.me/AlbanianCoinSol ",
    website: "https://www.albaniancoin.xyz/",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "AJdBSmueMDKWcL8QXGx4B7kCNft5iVXoUAt2kgwxZ4QN",
    name: "Romania Coin",
    twitter: "https://twitter.com/RomaniaOnSOL",
    telegram: "https://t.me/RomaniaOnSOL",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "Z83FZhr44ks3iQyJs4GEFRuq2fy82H2ha6wtUtpRqSF",
    name: "Serbia",
    twitter: "https://twitter.com/Serbia_sol",
    telegram: "https://t.me/serbiasol",
    website: "https://serbiasol.com/",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "DKpafe9zPbXgzBLgoPEcH81EJq7WGE9zEQZhe1sSPz4v",
    name: "Luxembourg ",
    twitter: "https://twitter.com/Luxonsol",
    telegram: "https://t.me/luxonsol",
    website: "https://www.luxonsol.com/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "3oC1mcKuMLAFp3LcD8r9nEi82YedGafXXKi3fN1Mzbqz",
    name: "Yemen Coin",
    twitter: "https://x.com/yemencoin_sol",
    telegram: "https://t.me/YemenCoinSol",
    website: "http://yemencoinsol.com/",
    categories: ["countries", "olympics"],
  },
  {
    ca: "7jYEdLMdksXJDTLZ3Q2DVchjoGhbRY7jKCQuMY4Dm8tt",
    name: "Philippines Coin",
    twitter: "https://twitter.com/PHILCoinSol",
    telegram: "https://t.me/PinoyCoinSol",
    categories: ["countries", "olympics"],
  },
  {
    ca: "H78DDnK8YiCFCMzxCrTMdatcun8JZTwYpgFrSHenRvGi",
    name: "LEBANON",
    twitter: "https://twitter.com/LebanonOnSol",
    telegram: "https://t.me/Lebanonsolcoin",
    categories: ["countries", "olympics"],
  },
  {
    ca: "6JiCKgS54oGhHgn3xbQrSj4xag4hcpnnUrb69FynwCfg",
    name: "BELGIUM",
    twitter: "https://twitter.com/belgiumonsol",
    telegram: "https://t.me/belgiumonsol",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "2JpLKSMREggPDDjyygxQD9rPHQoHnGBPRf5deYDXxKSa",
    name: "Greece Coin",
    twitter: "https://twitter.com/Greece_Sol",
    telegram: "https://t.me/Greece_Sol",
    categories: ["countries", "olympics"],
  },
  {
    ca: "4LcE6GDnDg2FuL1BKmLwLRDog3sWRBEj3XegRZPVfeLo",
    name: "Hungary",
    twitter: "https://x.com/HungaryCoin",
    telegram: "https://t.me/HUNGARYCOIN",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "5F4fMobvYU23iWtHtm7hUxk4DNW98SEPeD4y4ZJPi2Sc",
    name: "Kylian Mbappé",
    twitter: "https://x.com/mbappe_on_sol",
    telegram: "https://t.me/+KNTTtnbQSOJjZTY0",
    categories: ["players"],
    website: "https://www.mbappeonsol.xyz",
  },
  {
    ca: "HiRx4TWNmuPK6VKxGKPSw8T7dZ9NuCrakpNpRoiCEyrA",
    name: "Lionel Messi",
    twitter: "https://x.com/messiSol_CTO",
    telegram: "https://t.me/messiOnSol",
    categories: ["players"],
  },
  {
    ca: "BPGN6jP2aQDwx38MMTVskWv7iAwimFS24NaFE6rkr1dg",
    name: "Jude Bellingham",
    twitter: "https://x.com/JudeBOnSol",
    telegram: "https://t.me/+Bj_HtZ8aPRliMDQ0",
    categories: ["players"],
  },
  {
    ca: "3YY2PtCKzDEAvrRS1Lon9wHmMVLiMLs3FstvjvVVL2Z3",
    name: "Vinicius Jr",
    twitter: "https://x.com/ViniciusJrCoin",
    telegram: "https://t.me/ViniJrPortal",
    categories: ["players"],
    website: "https://vinijr.xyz",
  },
  {
    ca: "8XAy334rK8ESoJUeE4mjj2U356NamyUdEwwaYEcd2VJ6",
    name: "Harry Kane",
    twitter: "https://x.com/HarryKaneSol",
    telegram: "https://t.me/HarryKaneSOL",
    categories: ["players"],
  },
  {
    ca: "BHZDqqUuFJLX51upfnDjpNg5x4wniUQD88rLhE3oAP4j",
    name: "Cristiano Ronaldo on SOL",
    twitter: "https://x.com/CR7_CTO",
    telegram: "https://t.me/CristianoRonaldo_portal",
    categories: ["players"],
  },
  {
    ca: "AXevLJcvaFYj8pKwHLTPppBT7797pF58P9XiL1G26D6K",
    name: "Foden",
    twitter: "https://x.com/PhilFodenSOL",
    telegram: "https://t.me/philfodenonsolana",
    categories: ["players"],
    website: "https://philfodensol.xyz",
  },
  {
    ca: "3aeZtYm7iyLo4oNGXepEznPDVXyaNCmorcfoBKiCYEDx",
    name: "3aeZtYm7iyLo4oNGXepEznPDVXyaNCmorcfoBKiCYEDx",
    twitter: "https://x.com/Toni_Kroos_Sol",
    telegram: "https://t.me/tonikrooscoin",
    categories: ["players"],
  },
  {
    ca: "HmDVsFvANVFXv6CDr7eiveoTmJ25WGEoy3CoC2L6bpUM",
    name: "LukaModric",
    twitter: "https://x.com/LukaModricSol",
    telegram: "https://t.me/ModricSol",
    categories: ["players"],
    website: "https://modric.ai",
  },
  {
    ca: "3SBRHc9zJwU1ZByjYqMz4YUsNuuQruHafwCFy8UxXEYZ",
    name: "BUKAYO SAKA ",
    twitter: "https://x.com/BukayoSaka2424",
    telegram: "https://t.me/+nFAkRBfED1cwZmNl",
    categories: ["players"],
    website: "http://bukayosakasol.xyz",
  },
  {
    ca: "Ddbir3dBZnbNru5WRnuRbJ4aRQeGsq3KdHXPTVhfgGfq",
    name: "Griezmann Coin",
    twitter: "https://x.com/GriezmannCoin",
    telegram: "https://t.me/+XEozikI2pBozYTFk",
    categories: ["players"],
  },
  {
    ca: "HW99ti9uE9eR3y4CwA2NNY1b3si9PNXvDBsjokXbpTwk",
    name: "Neymar JR",
    twitter: "https://x.com/neymaronsolana",
    telegram: "https://t.me/neymarcoinonsol",
    categories: ["players"],
  },
  {
    ca: "J298REtzh4wbJWiQ8rJkAYiqk983b6FwpY98JGizBtnX",
    name: "Erling Haaland",
    telegram: "https://t.me/ErlingHaalandsol",
    categories: ["players"],
  },
  {
    ca: "GxCQ7ZfJmZNyBCUCCo1ZHrQtv5X6gGZe7utCVEazpCKA",
    name: "Lamine Yamal",
    twitter: "https://x.com/lamineyamalcto",
    telegram: "https://t.me/lamineyamalctosol",
    categories: ["players"],
    website: "https://lamineyamalsol.xyz",
  },
  {
    ca: "BuLtbsNdpKda4Wcimn4w3NSsHihVTBJfV1eabttNB24W",
    name: "ARDA GULER",
    twitter: "https://x.com/ardagulersol",
    telegram: "https://t.me/ardagulersol",
    categories: ["players"],
    website: "https://ardaguler.online",
  },
  {
    ca: "DYZNtbJTzzs8yqNtEK2Hd4dkBhRmaRsqGRKwQbV7pBeN",
    name: "Kevin De Bruyne",
    twitter: "https://x.com/KDBonSol",
    telegram: "https://t.me/+p2EHPXJeED0zMDJk",
    categories: ["players"],
    website: "https://kdbonsol.xyz",
  },
  {
    ca: "6KoqRjGTSTMsDJbpbNPhYy3JDovqqhHFZtiHyaZMbHk1",
    name: "RODRYGO GOES",
    twitter: "https://x.com/RoGoesOnSol",
    telegram: "https://t.me/rodrygoInSolCTO",
    categories: ["players"],
  },
  {
    ca: "6cJkMAub1KcBgoBLPJfR91dUnzFq9hpi8rSxnyH5q68o",
    name: "Diego Maradona",
    twitter: "https://x.com/Maradona_sol",
    telegram: "https://t.me/+ef-euN1_PlZlZTAx",
    categories: ["players"],
  },
  {
    ca: "5ABorz1x1wQ3krPfmKMxThZFkc1UWwQ1Qi27SXMdUGCb",
    name: "Harry Memeguire",
    twitter: "https://x.com/harry_on_sol",
    telegram: "https://t.me/harrymemeguire",
    categories: ["players"],
  },
  {
    ca: "BChnYYUNw9KFtqnZQ2vyGCJsqWYzkNrGBUMqW4cSmuJq",
    name: "PEPE",
    twitter: "https://x.com/realpepepepesol",
    telegram: "https://t.me/+RmVI5NHNJsE4MWMy",
    categories: ["players"],
  },
  {
    ca: "EawKQ9LMY3yu57oe6tbywF82XFrzmHkux4K49Hmemd3K",
    name: "Slovenia",
    twitter: "https://x.com/sloveniaonsol",
    telegram: "https://t.me/SloveniaOnSol",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "CvXSM1pnA4qy4wHY6h8f45MEHGG7dg3kzwuBU7qdN4fH",
    name: "Jack Grealish",
    twitter: "https://x.com/grealish_on_sol",
    telegram: "https://t.me/grealishportal",
    categories: ["players"],
    website: "https://www.grealishonsol.com",
  },
  {
    ca: "EDdoFGdpby82tS2JJ6X2koZhFkPK94hPv6RjmFvP6P3m",
    name: "Pele",
    categories: ["players"],
  },
  {
    ca: "65ZbAyj1FDroYYHjWoQyRd7MaGxQZiAsC1X2fHVoiK1s",
    name: "Giroud",
    twitter: "https://x.com/Giroudonsol",
    telegram: "https://t.me/giroudonsol",
    categories: ["players"],
  },
  {
    ca: "BQcH8EkfZmf2WNZAqbfrrKS4WDCcDqX8chtpRPPkftei",
    name: "Zlatan Ibrahimovic",
    twitter: "https://x.com/ibraonsolana/",
    telegram: "https://t.me/legendIbraCTOportal",
    categories: ["players"],
  },
  {
    ca: "Euc4TnUPkrWdvzavfvcynwdukb2FNCwuZ8Emt1gYBaY",
    name: "Great Britain",
    twitter: "https://x.com/GBRonSol",
    telegram: "https://t.me/GreatBritianGBR",
    categories: ["countries", "olympics"],
  },
  {
    ca: "GsemiGfuDBnKmDVST1h4sqL6AaehTP1M3izKd7PYm8P9",
    name: "Uruguay Coin",
    twitter: "https://x.com/uruguayonsolcto",
    telegram: "https://t.me/UruguayCTO",
    website: "https://uruguayonsol.xyz/",
    categories: ["countries", "olympics", "copa"],
  },
  {
    ca: "2ttc1ob5dabMcQBPSYqVLvLQD5XQf1RxSH1WBY6kMEz5",
    name: "Venezuela",
    twitter: "https://x.com/VenezuelaInSOL",
    telegram: "https://t.me/VENEZUELACOINSOL",
    categories: ["countries", "olympics", "copa"],
  },
  {
    ca: "kd6cAjxxjRVTMxY98TVPfP8uuy3ApcY9jQcJn86Pevo",
    name: "Scotland Coin",
    twitter: "https://x.com/Scotland_sol",
    telegram: "https://t.me/+wWwlZ_V2681lNjg0",
    categories: ["countries", "copa"],
  },
  {
    ca: "yA62zLicT9LyfZVxZ15YT4LXGLuX2Ut7eeReXPsWPvF",
    name: "Czech Republic",
    twitter: "https://x.com/czechonsolana",
    telegram: "https://t.me/czechonsolportal",
    categories: ["countries", "olympics", "euros"],
  },
  {
    ca: "HHTfpnbM38S4eKUb1AmhXNzuEBXkhGLQrcJU3nb7R7td",
    name: "Georgia Coin",
    twitter: "https://x.com/georgiacoinsol",
    telegram: "https://t.me/georgiacoinsol",
    categories: ["countries", "olympics", "euros"],
  },
];

export const CATEGORIES_AND_TOKENS: CategoryAndTokens[] = [
  {
    categoryName: "Countries",
    tokens: ALL_COINS.filter((e) =>
      e.categories.find((b) => b === "countries"),
    ),
    description: "All listed countries",
  },
  {
    categoryName: "Euros",
    tokens: ALL_COINS.filter((e) => e.categories.find((b) => b === "euros")),
    description:
      "Countries competing in the Euros 2024: Host Nation GERMANY\n14 Jun 2024 - 14 Jul 2024",
  },
  {
    categoryName: "Copa",
    tokens: ALL_COINS.filter((e) => e.categories.find((b) => b === "copa")),
    description:
      "Countries competing in the COPA America 2024: Host Nation USA\n21 Jun 2024 - 15 Jul 2024",
  },
  {
    categoryName: "Olympics",
    tokens: ALL_COINS.filter((e) => e.categories.find((b) => b === "olympics")),
    description:
      "Countries competing in the Summer Olympics 2024: Host Nation FRANCE\n26 July 2024 - 11 August 2024",
  },
];

// No Slovakia
// No Denmark
// No Czech Republic
// No Slovenia
// No Bolivia
// No Chile
// No Colombia
// No Ecuador
// No Panama
// No Paraguay
// No Peru
// No Trinidad
// No Tobago
// No Honduras
// No Costa Rica
