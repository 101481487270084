import {
  Container,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import FixedSocialsDisplay from "../Components/SocialsDisplay";

import CryptoFairyImage from "../Assets/crypto_fairy.webp";
import NationFiLogo from "../Assets/nationfi_logo.webp";
import { useNavigate } from "react-router-dom";

export type PageLayoutProps = {
  children: React.ReactNode;
};

export default function PageLayout({ children }: PageLayoutProps) {
  const navigate = useNavigate();

  return (
    <Container>
      <HStack
        width="full"
        mt={4}
        mb={4}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <HStack
          alignItems="center"
          onClick={() => {
            navigate("/");
          }}
          cursor="pointer"
        >
          <Heading size={{ base: "sm", md: "lg" }}>NationFiSolana</Heading>
          <Image
            src={NationFiLogo}
            borderRadius="10px"
            width="64px"
            height="64px"
            mb={2}
          />
        </HStack>
        <VStack
          mt={{ base: 4, md: "unset" }}
          alignItems={{ base: "center", md: "unset" }}
          spacing={0}
          width={{ base: "full", md: "unset" }}
        >
          <Heading size="2xs">Brought to you by</Heading>
          <HStack>
            <Text>Crypto Fairy</Text>
            <Image
              src={CryptoFairyImage}
              borderRadius="10px"
              width="48px"
              height="48px"
              mb={2}
            />
          </HStack>
          <FixedSocialsDisplay
            twitter="https://twitter.com/CryptoFairy___"
            telegram="https://t.me/+mQKUZ0ONMmEzM2E8"
          />
        </VStack>
      </HStack>

      {children}
    </Container>
  );
}
