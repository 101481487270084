import "../App.css";

type Props = {
  pairId: string;
};

export default function DexscreenerPairEmbed({ pairId }: Props) {
  return (
    <>
      <div id="dexscreener-embed">
        <iframe
          src={`https://dexscreener.com/solana/${pairId}?embed=1&theme=dark`}
        ></iframe>
      </div>
    </>
  );
}
