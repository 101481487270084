import {
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";

interface Props {
  label: string;
  value: string;
  loading: boolean;
  onClick?: () => void;
  clickText?: string;
}

export const Stat = (props: Props) => {
  const { label, value, loading, onClick, clickText, ...boxProps } = props;
  return (
    <Box
      px={{ base: "4", md: "6" }}
      py={{ base: "5", md: "6" }}
      bg="bg.surface"
      borderRadius="lg"
      boxShadow="sm"
      {...boxProps}
    >
      <Stack>
        <Text textStyle="sm" color="fg.muted">
          {label}
        </Text>
        <Heading size={{ base: "sm", md: "sm" }}>{value}</Heading>
        {onClick && clickText && (
          <Button size="xs" variant="countryGradient" onClick={onClick}>
            {clickText}
          </Button>
        )}
        <Center>{loading && <Spinner />}</Center>
      </Stack>
    </Box>
  );
};
