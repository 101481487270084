import { ChakraProvider } from "@chakra-ui/react";
import { countryStatsTheme } from "./ChakraUI/Theme";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePageIndex from "./Pages/HomePage/Index";
import ApplicationIndex from "./Pages/Application/Index";
import EventsIndex from "./Pages/Events/Index";

function App() {
  return (
    <ChakraProvider theme={countryStatsTheme}>
      <Router>
        <Routes>
          <Route path="*" element={<HomePageIndex />} />
          <Route path="/apply" element={<ApplicationIndex />} />
          <Route path="/events" element={<EventsIndex />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
